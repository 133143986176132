import React from "react";

import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ImageCard = ({ node }) => {
  const image = getImage(node);
  return (
    <>
      <div className="w-full h-full rounded-xl">
        <GatsbyImage image={image} alt={node.name} className="rounded-xl" />
      </div>
    </>
  );
};

const BlogPost = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;
  const images = data.images.nodes;
  const itemCount = data.images.pageInfo.itemCount;

  return (
    <Layout location={location} title="Home">
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="mb-4">
        <header className="mb-12">
          <h1 className="mb-2 pb-1 text-4xl font-bold">
            {post.frontmatter.title}
          </h1>
          <p className="block">{post.frontmatter.date}</p>
        </header>
        <section
          class="prose dark:prose-dark max-w-none"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr />
      </article>

      {itemCount > 0 && (
        <>
          <h4 className="mb-5 mt-5 text-5xl">
            Photo Gallery{" "}
            <span className="text-lg text-gray-500 font-semibold">
              {itemCount} photos
            </span>
          </h4>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
            {images.map((node) => (
              <ImageCard node={node} key={node.id} />
            ))}
          </div>

          <hr className="mt-4" />
        </>
      )}

      <nav>
        <ul className="flex flex-wrap justify-between list-none p-0">
          <li>
            {previous && (
              <Link
                to={previous.fields.slug}
                rel="prev"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>{" "}
                {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                to={next.fields.slug}
                rel="next"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                {next.frontmatter.title}{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    images: allFile(
      filter: {
        absolutePath: { regex: $slug }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      pageInfo {
        itemCount
      }
      nodes {
        name
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
